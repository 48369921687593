<template>
    <div class="mv4 pa4 bg-bond-grey cf">
        <h2 class="mv0">Data sources</h2>
        <p>
            Member information is provided to Bond by the member organisation,
            including the sustainable development goals they work on.
            Please <a href="mailto:membership@bond.org.uk" class="bond-red bond-link underline">contact us</a> if information is incorrect.
        </p>
        <p>
            Information on the countries that members work in is based on 
            the "area of operation" provided to the <a href="https://register-of-charities.charitycommission.gov.uk/" target="_blank" class="bond-red bond-link underline">Charity Commission for England and Wales</a>.
        </p> 
        <p>
            Some information is only available for charities registered in England and Wales.
            Charity information and logos are from <a href="https://charitybase.uk/" target="_blank" class="bond-red bond-link underline">CharityBase</a>.
        </p>
        <p>
            Countries and borders shown on the world map do not imply any expression of opinion on the part of Bond on the legal status of these areas.
        </p>
    </div>
</template>

<script>

export default {
    name: 'PageFooter',
}
</script>